.equipment {
    display: grid;
    grid-template-columns: 1fr 90rem 1fr;
    grid-template-rows: 30rem 8rem auto 10rem;

    @include respond(tab-port) {
        grid-template-columns: 1fr 50rem 1fr;
        grid-template-rows: 25rem 6rem auto 6rem;
    }

    @include respond(phone) {
        grid-template-columns: 1fr 40rem 1fr;
        grid-template-rows: 15rem 6rem auto 4rem;
    }

    @include respond(small-phone) {
        grid-template-columns: 1fr 30rem 1fr;
        grid-template-rows: 12rem 4rem auto 4rem;
    }

    // Banner
    &__banner {
        grid-column: 1 / -1;
        grid-row: 1 / 2;

        background-image: url(../../../assets/home/products/Equipment-Banner-Dark.jpg);
        background-size: cover;
        background-position: center;
    }

    &__banner-container {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        justify-self: center;
        align-self: center;
        text-align: center;
    }

    &__heading {
        color: $color-white;
        text-transform: uppercase;
        font-size: 4.5rem;

        @include respond(phone) {
            font-size: 3.6rem;
        }

        @include respond(small-phone) {
            margin-top: 2rem;
            font-size: 2.4rem;
        }
    }

    &__border {
        margin: 2rem auto;
        height: 2px;
        width: 15rem;
        background-color: $color-green;
    }

    // Containers
    &__container {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        
        display: grid;
        grid-template-rows: 15rem auto;
        grid-template-columns: repeat(3, 1fr);

        @include respond(tab-port) {
            grid-template-rows: 10rem auto;
        }
    }

    &__camera-button {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        justify-self: center;
        align-self: flex-start;

        width: 25rem;
        height: 6rem;

        border: 1px solid $color-grey-mild;
        background-color: $color-white;
        border-radius: 5px;
        outline: none;
        
        text-transform: uppercase;
        font-size: 2rem;
        color: $color-teal;

        transition: .3s all ease;

        @include respond(tab-port) {
            width: 12rem;
            height: 5rem;
            font-size: 1.4rem;
        }

        @include respond(small-phone) {
            width: 9rem;
            height: 4rem;
            font-size: 1rem;
        }

        &:hover {
            cursor: pointer;
            background-color: $color-grey-light;
        }
    }

    &__camera-button--active {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        justify-self: center;
        align-self: flex-start;

        width: 25rem;
        height: 6rem;

        border: none;
        background-color: $color-teal;
        border-radius: 5px;
        box-shadow: $shadow-button;
        outline: none;
        
        text-transform: uppercase;
        font-size: 2rem;
        color: $color-white;

        transition: .3s all ease;

        @include respond(tab-port) {
            width: 12rem;
            height: 5rem;
            font-size: 1.4rem;
        }

        @include respond(small-phone) {
            width: 9rem;
            height: 4rem;
            font-size: 1rem;
        }

        &:hover {
            cursor: pointer;
        }

        &:active {
            box-shadow: none;
        }
    }

    &__lighting-button {
        grid-column: 2 / 3;
        grid-row: 1 / 2;

        justify-self: center;
        align-self: flex-start;

        width: 25rem;
        height: 6rem;

        border: 1px solid $color-grey-mild;
        background-color: $color-white;
        border-radius: 5px;
        outline: none;
        
        text-transform: uppercase;
        font-size: 2rem;
        color: $color-teal;

        transition: .3s all ease;

        @include respond(tab-port) {
            width: 12rem;
            height: 5rem;
            font-size: 1.4rem;
        }

        @include respond(small-phone) {
            width: 9rem;
            height: 4rem;
            font-size: 1rem;
        }

        &:hover {
            cursor: pointer;
            background-color: $color-grey-light;
        }
    }

    &__lighting-button--active {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        justify-self: center;
        align-self: flex-start;

        width: 25rem;
        height: 6rem;

        border: none;
        background-color: $color-teal;
        border-radius: 5px;
        box-shadow: $shadow-button;
        outline: none;
        
        text-transform: uppercase;
        font-size: 2rem;
        color: $color-white;

        transition: .3s all ease;

        @include respond(tab-port) {
            width: 12rem;
            height: 5rem;
            font-size: 1.4rem;
        }

        @include respond(small-phone) {
            width: 9rem;
            height: 4rem;
            font-size: 1rem;
        }

        &:hover {
            cursor: pointer;
        }

        &:active {
            box-shadow: none;
        }
    }

    &__audio-button {
        grid-column: 3 / 4;
        grid-row: 1 / 2;

        justify-self: center;
        align-self: flex-start;

        width: 25rem;
        height: 6rem;

        border: 1px solid $color-grey-mild;
        background-color: $color-white;
        border-radius: 5px;
        outline: none;
        
        text-transform: uppercase;
        font-size: 2rem;
        color: $color-teal;

        transition: .3s all ease;

        @include respond(tab-port) {
            width: 12rem;
            height: 5rem;
            font-size: 1.4rem;
        }

        @include respond(small-phone) {
            width: 9rem;
            height: 4rem;
            font-size: 1rem;
        }

        &:hover {
            cursor: pointer;
            background-color: $color-grey-light;
        }
    }

    &__audio-button--active {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        justify-self: center;
        align-self: flex-start;

        width: 25rem;
        height: 6rem;

        border: none;
        background-color: $color-teal;
        border-radius: 5px;
        box-shadow: $shadow-button;
        outline: none;
        
        text-transform: uppercase;
        font-size: 2rem;
        color: $color-white;

        transition: .3s all ease;

        @include respond(tab-port) {
            width: 12rem;
            height: 5rem;
            font-size: 1.4rem;
        }

        @include respond(small-phone) {
            width: 9rem;
            height: 4rem;
            font-size: 1rem;
        }

        &:hover {
            cursor: pointer;
        }

        &:active {
            box-shadow: none;
        }
    }

    &__component-container {
        grid-column: 1 / -1;
        grid-row: 2 / 3;

        display: grid;
        grid-template-columns: repeat(4, 22.5rem);
        grid-template-rows: auto;

        @include respond(tab-port) {
            grid-template-columns: repeat(4, 12.5rem);
        }

        @include respond(phone) {
            grid-template-columns: repeat(3, 13.3333rem);
        }

        @include respond(small-phone) {
            grid-template-columns: repeat(3, 10rem);
        }
    }

    &__component-container-selected {
        grid-column: 1 / -1;
        grid-row: 1 / 3;

        display: grid;
        grid-template-columns: repeat(4, 22.5rem);
        grid-template-rows: auto;

        @include respond(tab-port) {
            grid-template-columns: repeat(4, 12.5rem);
        }

        @include respond(phone) {
            grid-template-columns: repeat(3, 13.3333rem);
        }

        @include respond(small-phone) {
            grid-template-columns: repeat(3, 10rem);
        }
    }

    // Product Components
    &__component {
        margin-bottom: 5rem;
        display: grid;
        grid-template-rows: 17rem 2rem;
        justify-content: center;
        transition: all .4s ease;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }

        &:active,
        &:focus {
            transform: scale(1);
            transition: all .3s ease;
        }

        @include respond(tab-port) {
            grid-template-rows: 11.5rem 2rem;
        }

        @include respond(small-phone) {
            grid-template-rows: 9.5rem 2rem;
            margin-bottom: 3.5rem;
        }
    }

    &__component-photo {
        width: 15rem;
        height: 15rem;
        align-self: flex-start;
        justify-self: center;

        border: 3px solid $color-teal;
        border-radius: 50%;
        box-shadow: $shadow-button;
        background-size: cover;
        background-position: center;

        transition: all .4s ease;

        @include respond(tab-port) {
            width: 10rem;
            height: 10rem;
        }

        @include respond(small-phone) {
            width: 8rem;
            height: 8rem;
        }
    }

    &__component:hover > &__component-photo {
        border: 3px solid $color-orange;
        box-shadow: $shadow-large;
    }

    &__component:active > &__component-photo {
        border: 3px solid $color-green;
        box-shadow: $shadow-button;
        transition: all .1s ease;
    }

    &__component-heading {
        font-size: 1.4rem;
        text-align: center;
        color: $color-grey;
        max-width: 18rem;
        align-self: flex-start;

        transition: all .4s ease;

        @include respond(tab-port) {
            font-size: 1.2rem;
            max-width: 11rem;
        }
        
        @include respond(small-phone) {
            font-size: 1rem;
            max-width: 9rem;
        }
    }

    &__component:hover > &__component-heading {
        color: #2d2d2d;
    }

    // Selected
    &__container-2 {
        grid-column: 1 / -1;
        grid-row: 1 / -1;

        display: grid;
        grid-template-columns: repeat(4, 22.5rem);
        grid-template-rows: 4rem 8rem 40rem auto;

        @include respond(tab-port) {
            grid-template-columns: repeat(4, 12.5rem);
            grid-template-rows: 8rem 6rem 30rem 32rem auto;
        }
    
        @include respond(phone) {
            grid-template-columns: repeat(4, 10rem);
            grid-template-rows: 8rem 6rem 30rem 35rem auto;
        }
    
        @include respond(small-phone) {
            grid-template-columns: repeat(4, 7.5rem);
            grid-template-rows: 8rem 6rem 20rem 36rem auto;
        }
    }

    &__selected-button {
        grid-column: 4 / 5;
        grid-row: 1 / 2;
        justify-self: flex-end;

        width: 10rem;
        height: 5rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        margin-top: -5rem;

        &:hover {
            cursor: pointer;
        }

        @include respond(tab-port) {
            margin-top: -3rem;
        }

        @include respond(small-phone) {
            margin-top: -2rem;
        }

    }

    &__back-icon {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 3.5rem;
        width: 3.5rem;
        margin-right: 1rem;
        fill: $color-teal;

        transition: all .3s ease;

        @include respond(small-phone) {
            height: 3rem;
            width: 3rem;
            justify-self: flex-end;
            margin-right: 0rem;
        }
    }

    &__selected-button:hover > &__back-icon {
        fill: $color-teal-dark;
        transform: scale(1.05);
        transform: translateX(-40px);
    }

    &__back-text {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        color: $color-teal;
        font-weight: bold;
        margin-top: .5rem;
        opacity: 0;

        transition: all .5s ease;
    }

    &__selected-button:hover > &__back-text {
        opacity: 1;
    }

    &__previous {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        justify-self: flex-start;
        align-self: center;

        height: 3rem;
        width: 14rem;

        border: none;
        outline: none;
        color: $color-teal;
        text-decoration: underline;
        font-weight: bold;

        transition: all .3s ease;

        &:hover {
            cursor: pointer;
            background-color: $color-teal;
            color: #fff;
            border-radius: 5px;
            box-shadow: $shadow-medium;
            transform: scale(1.05);
            text-decoration: none;
        }

        @include respond(small-phone) {
            font-size: .8rem;
            height: 3rem;
            width: 12rem;
        }
    }

    &__next {
        grid-column: 4 / 5;
        grid-row: 1 / 2;
        justify-self: flex-end;
        align-self: center;

        height: 3rem;
        width: 14rem;

        border: none;
        outline: none;
        color: $color-teal;
        text-decoration: underline;
        font-weight: bold;

        transition: all .3s ease;

        &:hover {
            cursor: pointer;
            background-color: $color-teal;
            color: #fff;
            border-radius: 5px;
            box-shadow: $shadow-medium;
            transform: scale(1.05);
            text-decoration: none;
        }

        @include respond(small-phone) {
            font-size: .8rem;
            height: 3rem;
            width: 12rem;
        }
    }

    // Main Selected
    &__selected-heading {
        grid-column: 1 / -1;
        grid-row: 2 / 3;

        font-size: 2.4rem;
        text-align: center;
        color: $color-grey;

        @include respond(tab-port) {
            grid-column: 1 / -1;
            grid-row: 2 / 3;
        }

        @include respond(small-phone) {
            font-size: 2rem;
        }
    }

    &__selected-photo {
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        justify-self: center;
        align-self: center;

        width: 40rem;
        height: 40rem;
        align-self: center;
        justify-self: center;

        border: 5px solid $color-green;
        border-radius: 50%;
        box-shadow: $shadow-button;
        background-size: cover;
        background-position: center;

        @include respond(tab-port) {
            grid-column: 1 / -1;
            grid-row: 3 / 4;
            width: 30rem;
            height: 30rem;
        }

        @include respond(small-phone) {
            width: 20rem;
            height: 20rem;
        }
    }

    &__selected-description {
        grid-column: 1 / 5;
        grid-row: 4 / 5;

        justify-self: center;
        align-self: center;
        margin-top: 8rem;
        margin-bottom: 4rem;

        color: $color-grey;

        @include respond(tab-port) {
            grid-column: 1 / -1;
            grid-row: 5 / 6;
            font-size: 1.4rem;
        }

        @include respond(small-phone) {
            font-size: 1.2rem;
        }
    }

    // Prices Container
    &__prices-container {
        grid-column: 3 / 5;
        grid-row: 2 / 4;

        display: grid;
        grid-template-columns: 1fr repeat(3, 12rem);
        grid-template-rows: 8rem repeat(2, 6rem) 4rem repeat(2, 6rem) 1fr;

        @include respond(tab-port) {
            grid-column: 1 / -1;
            grid-row: 4 / 5;
            grid-template-columns: repeat(3, 16.6666rem);
            grid-template-rows: 4rem repeat(2, 6rem) 4rem repeat(2, 6rem) 1fr;
        }

        @include respond(phone) {
            grid-template-columns: repeat(3, 13.3333rem);
            justify-self: center;
        }

        @include respond(phone) {
            grid-template-columns: repeat(3, 10rem);
            grid-template-rows: 4rem repeat(2, 6rem) 6rem repeat(2, 6rem) 1fr;
        }
    }

    &__prices-heading-1 {
        grid-column: 2 / -1;
        grid-row: 2 / 3;
        align-self: center;

        text-align: center;
        color: $color-grey;

        @include respond(tab-port) {
            grid-column: 1 / -1;
        }
    }

    &__prices-heading-2 {
        grid-column: 2 / -1;
        grid-row: 5 / 6;
        align-self: center;

        text-align: center;
        color: $color-grey;

        @include respond(tab-port) {
            grid-column: 1 / -1;
        }
    }

    &__prices {
        align-self: center;
        justify-self: center;
        z-index: 1;

        &--1 {
            grid-column: 2 / 3;
            grid-row: 3 / 4;

            @include respond(tab-port) {
                grid-column: 1 / 2;
            }
        }

        &--2 {
            grid-column: 3 / 4;
            grid-row: 3 / 4;

            @include respond(tab-port) {
                grid-column: 2 / 3;
            }
        }

        &--3 {
            grid-column: 4 / 5;
            grid-row: 3 / 4;

            @include respond(tab-port) {
                grid-column: 3 / 4;
            }
        }

        &--4 {
            grid-column: 2 / 3;
            grid-row: 6 / 7;

            @include respond(tab-port) {
                grid-column: 1 / 2;
            }
        }

        &--5 {
            grid-column: 3 / 4;
            grid-row: 6 / 7;

            @include respond(tab-port) {
                grid-column: 2 / 3;
            }
        }

        &--6 {
            grid-column: 4 / 5;
            grid-row: 6 / 7;

            @include respond(tab-port) {
                grid-column: 3 / 4;
            }
        }
    }

    &__button {
        width:10rem;
        height: 4rem;

        background-color: $color-green;
        border: none;
        border-radius: 5px;
        box-shadow: $shadow-button;
        color: $color-white;
        text-transform: uppercase;
        outline: none;
        font-weight: 600;
        text-decoration: none;

        transition: .3s all ease;

        &:hover {
            cursor: pointer;
            background-color: $color-grey-light;
            color: $color-green;
        }

        &:active {
            box-shadow: none;
        }

        @include respond(phone) {
            width:8rem;
            height: 4rem;
        }
    }

    &__prices-price {
        align-self: center;
        justify-self: center;
        z-index: 0;
        color: $color-teal;
        font-weight: bold;
        font-size: 2.4rem;

        transition: all .3s ease;

        &--1 {
            grid-column: 2 / 3;
            grid-row: 3 / 4;
            animation: priceReveal 1s 1s forwards;

            @include respond(tab-port) {
                grid-column: 1 / 2;
            }
        }

        &--2 {
            grid-column: 3 / 4;
            grid-row: 3 / 4;
            animation: priceReveal 1s 1.1s forwards;

            @include respond(tab-port) {
                grid-column: 2 / 3;
            }
        }

        &--3 {
            grid-column: 4 / 5;
            grid-row: 3 / 4;
            animation: priceReveal 1s 1.2s forwards;

            @include respond(tab-port) {
                grid-column: 3 / 4;
            }
        }

        &--4 {
            grid-column: 2 / 3;
            grid-row: 6 / 7;
            animation: priceReveal 1s 1.3s forwards;

            @include respond(tab-port) {
                grid-column: 1 / 2;
            }
        }

        &--5 {
            grid-column: 3 / 4;
            grid-row: 6 / 7;
            animation: priceReveal 1s 1.4s forwards;

            @include respond(tab-port) {
                grid-column: 2 / 3;
            }
        }

        &--6 {
            grid-column: 4 / 5;
            grid-row: 6 / 7;
            animation: priceReveal 1s 1.5s forwards;

            @include respond(tab-port) {
                grid-column: 3 / 4;
            }
        }
    }

    &__prices--1:hover + &__prices-price--1 {
        transform: translateY(5rem);

        @include respond(tab-port) {
            transform: none;
        }
    }

    &__prices--2:hover + &__prices-price--2 {
        transform: translateY(5rem);

        @include respond(tab-port) {
            transform: none;
        }
    }

    &__prices--3:hover + &__prices-price--3 {
        transform: translateY(5rem);

        @include respond(tab-port) {
            transform: none;
        }
    }

    &__prices--4:hover + &__prices-price--4 {
        transform: translateY(5rem);

        @include respond(tab-port) {
            transform: none;
        }
    }

    &__prices--5:hover + &__prices-price--5 {
        transform: translateY(5rem);

        @include respond(tab-port) {
            transform: none;
        }
    }

    &__prices--6:hover + &__prices-price--6 {
        transform: translateY(5rem);

        @include respond(tab-port) {
            transform: none;
        }
    }
}

@keyframes priceReveal {
    0% {
        transform: translateY(0rem);

    }

    100% {
        transform: translateY(5rem);
    }
}