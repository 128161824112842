#about__3-paragraph-1 {
    margin: 8rem 0;

    @include respond(tab-port) {
        margin: 6rem 0;
    }

    @include respond(phone) {
        margin: 4rem 0;
    }
}

#about__3-work-video {
    align-self: center;
    width: 448px;
    height: 252px;

    @include respond(phone) {
        width: 358px;
        height: 204px;
    }

    @include respond(small-phone) {
        width: 286px;
        height: 163px;
    }
}

#about__3-work-description {
    width: 30rem;

    @include respond(tab-land) {
        margin: 4rem 0;
    }

    @include respond(tab-port) {
        width: 40rem;
    }

    @include respond(small-phone) {
        width: 30rem;
    }
}

.about__3-work-border {
    width: 20rem;
    margin: 6rem auto;

    @include respond(tab-land) {
        margin: 0 auto 8rem;
    }

    @include respond(tab-port) {
        margin: 0 auto 6rem;
    }

    @include respond(phone) {
        width: 15rem;
        margin: 0 auto 4rem;
    }

    &--1 {
        border-bottom: 3px solid $color-teal;
    }

    &--2 {
        border-bottom: 3px solid $color-orange;
    }

    &--3 {
        border-bottom: 3px solid $color-green;
    }
}

#about__3-work-button-link {
    margin: 0 auto 10rem;

    @include respond(tab-port) {
        margin: 0 auto 8rem;
    }

    @include respond(small-phone) {
        margin: 0 auto 6rem;
    }
}
