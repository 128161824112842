#about__4-icon-container {
    width: 45rem;
    margin: 8rem auto 0;

    @include respond(tab-land) {
        margin: 6rem auto 0;
    }

    @include respond(phone) {
        width: 35rem;
    }

    @include respond(small-phone) {
        width: 28rem;
    }
}

#about__4-icon-link {
    width: 3rem;
    height: 3rem;
}

.about__4-icon {
    width: 3rem;
    height: 3rem;
    fill: $color-teal;

    &--1 {
        animation: iconColor 7s ease infinite;
    }

    &--2 {
        animation: iconColor 7s .5s ease infinite;
    }

    &--3 {
        animation: iconColor 7s 1s ease infinite;
    }

    &--4 {
        animation: iconColor 7s 1.5s ease infinite;
    }

    &--5 {
        animation: iconColor 7s 2s ease infinite;
    }

    @include respond(small-phone) {
        width: 2.5rem;
        height: 2.5rem;
    }
}

#about__4-container {
    margin: 8rem auto 10rem;

    @include respond(tab-land) {
        margin: 6rem auto 10rem;
        flex-direction: column-reverse;
    }
}

#about__4-map-container {
    align-self: flex-start;

    @include respond(tab-land) {
        align-self: center;
        margin-top: 6rem;
    }
}

#about__4-contact-map {
    border: .4rem solid $color-orange;
    transition: all .5s;
    align-self: flex-end;

    width: 480px;
    height: 360px;

    &:hover {
        border: .4rem solid $color-green;
    }

    @include respond(tab-land) {
        width: 384px;
        height: 288px;
    }

    @include respond(tab-port) {
        width: 480px;
        height: 360px;
    }

    @include respond(phone) {
        width: 374px;
        height: 278px;
    }

    @include respond(small-phone) {
        width: 299px;
        height: 222px;
    }
}

#about__4-email {
    margin-top: 3rem;
}

#about__4-phone {
    margin-top: 1rem;
}

#about__4-heading-1 {
    margin-top: 6rem;
}

#about__4-heading-2 {
    margin-top: 4rem;

    @include respond(small-phone) {
        margin-top: 2rem;
    }
}

#about__4-heading-3 {
    margin-top: 2rem;

    @include respond(small-phone) {
        margin-top: 1rem;
    }
}

#about__4-link {
    text-decoration: none;
    transition: all .2s ease;
    border-bottom: 2px solid transparent;

    &:hover {
        border-bottom: 2px solid $color-green;
        color: $color-green;
    }
}

@keyframes iconColor {
    0% {
        fill: $color-teal;
    }

    33% {
        fill: $color-orange;
    }

    66% {
        fill: $color-green;
    }

    100% {
        fill: $color-teal;
    }
}

// Form
#about__4-contact-form-container {
    align-self: flex-start;

    @include respond(tab-land) {
        align-self: center;
    }
}

#about__4-contact-form {
    margin: 0 auto;
    padding-top: 4rem;
    width: 38rem;

    @include respond(tab-land) {
        width: 50rem;
    }

    @include respond(phone) {
        width: 40rem;
    }

    @include respond(small-phone) {
        width: 30rem;
    }
}

#about__4-form-line {
    margin: 2rem 0 1rem;
}

#about__4-form-button-container {
    margin-top: 6rem;
}
