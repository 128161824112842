.membership-notify {
    position: fixed;
    top: 0rem;
    right: 0rem;

    height: 6rem;
    width: 100vw;

    background-color: $color-green;
    z-index: 11;
    transform: translateY(-6rem);
    box-shadow: $shadow-medium;

    animation: MembershipNotifyIn 1s ease 4s forwards;

    display: grid;
    grid-template-columns: 1fr 30rem 30rem 1fr;
    grid-template-rows: 1fr;

    @include respond(tab-port) {
        grid-template-columns: 1fr 20rem 20rem 1fr;
    }

    @include respond(phone) {
        grid-template-columns: 1fr 19rem 11rem 1fr;
    }

    &__paragraph {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        align-self: center;

        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 2rem;

        @include respond(tab-port) {
            justify-self: flex-start;
            grid-column: 2 / 4;
            font-size: 1.8rem;
        }

        @include respond(phone) {
            justify-self: flex-end;
            grid-column: 1 / 3;
            font-size: 1.6rem;
        }

        @include respond(small-phone) {
            font-size: 1.2rem;
        }
    }

    &__link {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        justify-self: flex-end;
        align-self: center;

        @include respond(small-phone) {
            justify-self: center;
        }
    }

    &__button {
        background: $color-green;
        color: #fff;
        width: 10rem;
        height: 3.5rem;
        border: solid 2px #fff;
        border-radius: 25px;
        font-size: 1.4rem;
        font-weight: bold;
        margin-right: 2rem;
        outline: none;

        transition: all .3s ease;

        @include respond(tab-port) {
            margin-right: 0;
        }

        @include respond(small-phone) {
            width: 8rem;
            height: 3rem;
            font-size: 1.2rem;
        }

        &:hover {
            cursor: pointer;
            background: #fff;
            color: $color-green;
        }
    }

    &__close {
        grid-column: 4 / 5;
        grid-row: 1 / 2;
        justify-self: flex-end;
        align-self: center;

        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        margin-right: 2rem;

        background: $color-green;
        border: 2px solid #fff;
        outline: none;
        transition: all .3s ease;

        @include respond(tab-port) {
            width: 3rem;
            height: 3rem;
        }

        @include respond(small-phone) {
            width: 2.5rem;
            height: 2.5rem;
        }

        &:hover {
            background-color: $color-grey-light;
            cursor: pointer;
        }

        &:active {
            background-color: $color-white;
        }

        &:active .header__menu-icon {
            fill: $color-white; 
        }
    }

    &__close-icon {
        width: 2.5rem;
        height: 2.5rem;
        fill: #fff;
        transition: all .3s ease;

        @include respond(tab-port) {
            width: 2rem;
            height: 2rem;
        }

        @include respond(small-phone) {
            width: 1.5rem;
            height: 1.5rem;
        }

        &:hover {
            fill: $color-green;
            cursor: pointer;
        }
    }
}

@keyframes MembershipNotifyIn {
    0% {
        transform: translateY(-6rem);
    }

    100% {
        transform: translateY(0rem);
    }
}