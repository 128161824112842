*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

::selection {
    background: $color-teal-dark;
    color: white;
  }

::-moz-selection {
    background: $color-orange;
    color: white;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 10px
}

body {
    font-family: $font-main;
    font-size: 1.8rem;
    color: $color-grey;
}

.small-sidebar-container {
    display: grid;
    grid-template-rows: 6rem auto 50rem;
    grid-template-columns: [sidebar-start] 7rem
                            [sidebar-end main-start] 1fr
                            [main-end];

    @include respond(phone) {
        grid-template-rows: 6rem minmax(100vh, 1fr);
    }
}

.large-sidebar-container {
    display: grid;
    grid-template-rows: 6rem auto 50rem;
    grid-template-columns: [sidebar-start] 25rem
                            [sidebar-end main-start] 1fr
                            [main-end];

    @include respond(phone) {
        grid-template-rows: 6rem minmax(100vh, 1fr);
    }
}