#about__2-row-container {
    margin-top: 6rem;

    @include respond(tab-land) {
        display: grid;
        flex-direction: none;
    }

    @include respond(tab-port) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

#about__2-row-container-2 {
    margin: 6rem 0 10rem;

    @include respond(tab-land) {
        display: grid;
        flex-direction: none;
    }

    @include respond(tab-port) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

#about__2-left-container {
    @include respond(tab-land) {
        grid-column: 1 / 2;
    }

    @include respond(tab-port) {
        grid-column: none;
        margin-bottom: 6rem;
    }
}

#about__2-right-container {
    @include respond(tab-land) {
        grid-column: 2 / 3;
    }

    @include respond(tab-port) {
        grid-column: none;
    }
}

.about {
    // Team Cards
    &__team-card-container {
        justify-self: center;
        align-self: center;

        perspective: 150rem;
        -webkit-perspective: 150rem;
        -moz-perspective: 150rem;
        height: 50rem;
        width: 36rem;
        border: none !important;

        @include respond(phone) {
            height: 45rem;
            width: 31rem;
        }

        @include respond(small-phone) {
            height: 40rem;
            width: 26rem;
        }

        &:hover .about__team-card--front {
            transform: rotateY(180deg);
        }

        &:hover .about__team-card--back {
            transform: rotateY(0deg);
        }
    }

    &__team-card {
        height: 100%;
        transition: all .8s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        backface-visibility: hidden;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: $shadow-large;

        &--front {
            background-color: $color-white;
        }

        &--back {
            transform: rotateY(-180deg);
            z-index: 20;

            &--1 {
                background-color: $color-teal;
            }

            &--2 {
                background-color: $color-orange;
            }

            &--3 {
                background-color: $color-green;
            }
        }
    }

    &__team-card-picture { 
        background-size: cover;
        height: 100%;
        position: absolute;
        left: -2rem;
        width: 110%;

        &--2 {
            background-image: url(../../../assets/about/team/Joe.png);
        }

        &--3 {
            background-image: url(../../../assets/about/team/Chelsea.png);
        }

        &--4 {
            background-image: url(../../../assets/about/team/Jake.png);
        }

        &--5 {
            background-image: url(../../../assets/about/team/Alex.png);
        }

        &--6 {
            background-image: url(../../../assets/about/team/Mike.png);
        }
    }

    &__team-card-name {
        text-transform: uppercase;
        text-align: right;

        position: absolute;
        top: 38rem;
        right: 2rem;
        width: 75%;

        @include respond(small-phone) {
            top: 34rem;
            right: 1rem;
        }
    }

    &__team-card-name-span {
        padding: 1rem 1.5rem;
        
        &--1 {
            background-image: linear-gradient(to right bottom, 
                rgba($color-teal, .85), 
                rgba($color-teal, .85));
        }

        &--2 {
            background-image: linear-gradient(to right bottom, 
                rgba($color-orange, .85), 
                rgba($color-orange, .85));
        }

        &--3 {
            background-image: linear-gradient(to right bottom, 
                rgba($color-green, .85), 
                rgba($color-green, .85));
        }
    }

    &__team-card-role {
        text-transform: uppercase;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    &__team-card-info-1 {
        padding: 1rem 3rem;
    }

    &__team-card-info-2 {
        padding: 3rem;

        @include respond(phone) {
            padding: 1rem 3rem;
        }
    }
}
