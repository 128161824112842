.button {
    align-items: center;
    height: 5rem;
    background-color: $color-green;
    border: none;
    border-radius: 5px;
    box-shadow: $shadow-button;
    color: $color-white;
    text-transform: uppercase;
    outline: none;
    font-weight: 100;

    transition: .3s all ease;

    &--s {
        width: 6rem;
        height: 4rem;

        @include respond(tab-port) {
            width: 5rem;
            height: 4rem;
            font-size: 1rem;
        }

        @include respond(small-phone) {
            width: 4rem;
            height: 3rem;
            font-size: 1rem;
        }
    }

    &--m {
        width: 10rem;

        @include respond(tab-port) {
            width: 9rem;
            height: 4rem;
            font-size: 1.4rem;
        }

        @include respond(phone) {
            width: 8rem;
            height: 3rem;
            font-size: 1.2rem;
        }
    }

    &--l {
        width: 15rem;

        @include respond(tab-port) {
            width: 14rem;
            height: 5rem;
            font-size: 1.4rem;
        }

        @include respond(phone) {
            width: 12rem;
            height: 5rem;
            font-size: 1.2rem;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: $color-grey-light;
        color: $color-green;
    }

    &:active {
        box-shadow: none;
    }
}

.button-2 {
    width: 25rem;
    height: 6rem;

    border: 1px solid $color-grey-mild;
    background-color: $color-white;
    border-radius: 5px;
    outline: none;
    
    text-transform: uppercase;
    font-size: 2rem;
    color: $color-teal;

    transition: .3s all ease;

    @include respond(tab-port) {
        width: 10rem;
        height: 5rem;
        font-size: 1.2rem;
        justify-self: flex-start;
    }

    @include respond(phone) {
        width: 9rem;
        font-size: 1.1rem;
    }

    @include respond(small-phone) {
        width: 9rem;
        height: 4rem;
        font-size: 1rem;
    }

    &:hover {
        cursor: pointer;
        background-color: $color-grey-light;
    }
}

.button-2--active {
    width: 25rem;
    height: 6rem;

    border: none;
    background-color: $color-teal;
    border-radius: 5px;
    box-shadow: $shadow-button;
    outline: none;
    
    text-transform: uppercase;
    font-size: 2rem;
    color: $color-white;

    transition: .3s all ease;

    @include respond(tab-port) {
        width: 10rem;
        height: 5rem;
        font-size: 1.2rem;
        justify-self: flex-start;
    }

    @include respond(phone) {
        width: 9rem;
        font-size: 1.1rem;
    }

    @include respond(small-phone) {
        width: 9rem;
        height: 4rem;
        font-size: 1rem;
    }

    &:hover {
        cursor: pointer;
    }

    &:active {
        box-shadow: none;
    }
}
