#services__heading {
    margin: 8rem 0 4rem;
    text-align: center;

    @include respond(phone) {
        margin: 6rem 0 2rem;
    }
}

#services__paragraph {
    margin: 0 auto;
    text-align: center;
    width: 65rem;

    @include respond(tab-port) {
        width: 50rem;
    }

    @include respond(phone) {
        width: 38rem;
    }

    @include respond(small-phone) {
        width: 30rem;
    }
}

#services__photo-container {
    width: 65rem;
    height: 15rem;
    margin: 8rem auto 2rem;

    @include respond(tab-port) {
        width: 40rem;
    }

    @include respond(phone) {
        width: 38rem;
        margin: 2rem auto 2rem;
    }

    @include respond(small-phone) {
        width: 30rem;
    }
}

.services__photo {
    width: 15rem;
    height: 15rem;
    background-size: cover;
    background-position: center;
    box-shadow: $shadow-medium;

    @include respond(tab-port) {
        width: 12rem;
        height: 12rem;
    }

    @include respond(small-phone) {
        width: 14rem;
        height: 14rem;
    }

    &--1 {
        border-bottom: 4px solid $color-teal;

        @include respond(tab-port) {
            display: none;
            border-bottom: none;
        }
    }

    &--2 {
        border-bottom: 4px solid $color-orange;

        @include respond(tab-port) {
            border-bottom: 4px solid $color-teal;
            justify-self: center;
        }

        @include respond(small-phone) {
            grid-column: 1 / 2;
        }
    }

    &--3 {
        border-bottom: 4px solid $color-green;

        @include respond(tab-port) {
            border-bottom: 4px solid $color-orange;
            justify-self: center;
        }

        @include respond(small-phone) {
            display: none;
            border-bottom: none;
        }
    }

    &--4 {
        border-bottom: 4px solid $color-teal;

        @include respond(tab-port) {
            border-bottom: 4px solid $color-green;
            justify-self: center;
        }

        @include respond(small-phone) {
            border-bottom: 4px solid $color-orange;
        }
    }

    &--5 {
        border-bottom: 4px solid $color-orange;

        @include respond(tab-port) {
            justify-self: center;
        }

        @include respond(small-phone) {
            border-bottom: 4px solid $color-green;
        }
    }

    &--6 {
        border-bottom: 4px solid $color-green;

        @include respond(tab-port) {
            display: none;
            border-bottom: none;
        }
    }

    &--7 {
        border-bottom: 4px solid $color-teal;

        @include respond(tab-port) {
            justify-self: center;
            border-bottom: 4px solid $color-green;
        }

        @include respond(small-phone) {
            border-bottom: 4px solid $color-teal;
        }
    }

    &--8 {
        border-bottom: 4px solid $color-orange;

        @include respond(tab-port) {
            border-bottom: 4px solid $color-teal;
            justify-self: center;
        }

        @include respond(small-phone) {
            display: none;
            border-bottom: none;
        }
    }

    &--9 {
        border-bottom: 4px solid $color-green;

        @include respond(tab-port) {
            justify-self: center;
        }

        @include respond(small-phone) {
            border-bottom: 4px solid $color-orange;
        }
    }

    &--10 {
        border-bottom: 4px solid $color-teal;

        @include respond(tab-port) {
            display: none;
            border-bottom: none;
        }
    }

    &--11 {
        border-bottom: 4px solid $color-orange;

        @include respond(tab-port) {
            border-bottom: 4px solid $color-teal;
            justify-self: center;
        }

        @include respond(small-phone) {
            border-bottom: 4px solid $color-green;
        }
    }

    &--12 {
        border-bottom: 4px solid $color-green;

        @include respond(tab-port) {
            border-bottom: 4px solid $color-orange;
            justify-self: center;
        }

        @include respond(small-phone) {
            display: none;
            border-bottom: none;
        }
    }
}

#services__button-container {
    justify-content: center;
    margin: 8rem 0 10rem;

    @include respond(phone) {
        margin: 4rem 0 8rem;
    }
}
