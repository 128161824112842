.container {
    @include respond(big-desktop) {
        grid-template-columns: 1fr repeat(12, 10rem) 1fr;
    }

    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr repeat(12, 7.5rem) 1fr;
    grid-template-rows: auto;

    @include respond(tab-port) {
        grid-template-columns: 1fr repeat(6, 10rem) 1fr;
    }

    @include respond(phone) {
        grid-template-columns: 1fr repeat(6, 6.25rem) 1fr;
    }

    @include respond(small-phone) {
        grid-template-columns: 1fr repeat(6, 5.333rem) 1fr;
    }
}

.container-new {
    @include respond(big-desktop) {
        width: 120rem;
        margin: 0 auto;
    }
    
    width: 90rem;
    margin: 0 auto;

    @include respond(tab-port) {
        width: 60rem;
    }

    @include respond(phone) {
        width: 37.5rem;
    }

    @include respond(small-phone) {
        width: 32rem;
    }
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

// COLUMNS
.col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    @include respond(tab-land) {
        display: flex;
        flex-direction: column;
    }
}

.col-2-1 {
    grid-column: 1 / 2;
    display: flex;
    align-self: center;
    justify-content: center;

    @include respond(tab-land) {
        grid-column: none;
    }
}

.col-2-2 {
    grid-column: 2 / 3;
    display: flex;
    align-self: center;
    justify-content: center;

    @include respond(tab-land) {
        grid-column: none;
    }
}

.col-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;

    @include respond(tab-land) {
        display: flex;
        flex-direction: column;
    }
}

.col-3-1 {
    grid-column: 1 / 2;

    @include respond(tab-land) {
        grid-column: none;
    }
}

.col-3-2 {
    grid-column: 2 / 3;

    @include respond(tab-land) {
        grid-column: none;
    }
}

.col-3-3 {
    grid-column: 3 / 4;

    @include respond(tab-land) {
        grid-column: none;
    }
}
