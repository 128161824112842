.main-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0,0,0,0.70);
    z-index: -1;
    opacity: 0;


    &__fadeIn {
        animation: fadeIn .3s;
        z-index: 11;
        opacity: 1;
    }
    
    &__fadeOut {
        animation: fadeOut .3s;
        z-index: -1;
        opacity: 0;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            z-index: -1;
        }

        1% {
            z-index: 10;
        }

        100% {
            opacity: 1;
            z-index: 10;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
            z-index: 10;
        }

        99% {
            z-index: 10;
        }

        100% {
            opacity: 0;
            z-index: -1;
        }
    }
}

.services-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0,0,0,0.70);
    z-index: -1;
    opacity: 0;


    &__fadeIn {
        animation: fadeIn .3s;
        z-index: 10;
        opacity: 1;
    }
    
    &__fadeOut {
        animation: fadeOut .3s;
        z-index: -1;
        opacity: 0;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            z-index: -1;
        }

        1% {
            z-index: 10;
        }

        100% {
            opacity: 1;
            z-index: 10;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
            z-index: 10;
        }

        99% {
            z-index: 10;
        }

        100% {
            opacity: 0;
            z-index: -1;
        }
    }
}
