// MEIA QUERY MANAGER
/* 

0 - 600px: Phone
600 - 900px: Tablet Portrait
900 -1200px: Tablet Landscape
1200 - 1800px: where our normal styles apply
1800px + : Big Desktop

*/


/*
$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components
*/

@mixin respond($breakpoint) {
    @if $breakpoint == small-phone {
        @media(max-width: 25em) { @content }; // 375px
    }
    
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content }; // 600px
    }
    
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content }; // 900px
    }

    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content }; // 1200px
    }

    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content }; // 1800px
    }
}