// Color
@keyframes color {
    0% {
        background-color: $color-teal;
    }

    33% {
        background-color: $color-orange;
    }

    66% {
        background-color: $color-green;
    }

    100% {
        background-color: $color-teal;
    }
}

// Products
@keyframes onRentalFadeIn {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes onServiceFadeIn {
    0% {
        z-index: 0;
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        z-index: 0;
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes onMembershipsFadeIn {
    0% {
        z-index: 0;
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        z-index: 0;
        opacity: 1;
        transform: translateX(0);
    }
}
