.swag {
    display: grid;
    grid-template-columns: 1fr 90rem 1fr;
    grid-template-rows: 30rem 8rem 12rem auto 10rem;

    @include respond(tab-port) {
        grid-template-columns: 1fr 50rem 1fr;
        grid-template-rows: 25rem 7rem 15rem auto 6rem;
    }

    @include respond(phone) {
        grid-template-columns: 1fr 40rem 1fr;
        grid-template-rows: 15rem 5rem 14rem auto 4rem;
    }

    @include respond(small-phone) {
        grid-template-columns: 1fr 30rem 1fr;
        grid-template-rows: 12rem 5rem 15rem auto 4rem;
    }

    &__banner {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        background-size: cover;
        background-position: center;
    }

    &__banner-container {
        grid-column: 2 / -2;
        grid-row: 1 / 2;
        justify-self: center;
        align-self: center;
        text-align: center;

        background-color: rgba(255, 255, 255, 0.9);
        padding: 3rem;
        width: 60rem;

        border-radius: 15px;

        @include respond(tab-port) {
            width: 50rem;
            grid-column: 2 / -2;
        }

        @include respond(phone) {
            width: 100%;
            padding: 1.5rem;
        }
    }

    &__heading {
        color: $color-grey-hover;
        text-transform: uppercase;
    }

    &__border {
        margin: 2rem auto;
        height: 2px;
        width: 15rem;
        background-color: $color-green;

        @include respond(phone) {
            margin: 1rem auto;
        }
    }

    // MAIN
    &__back-button {
        grid-column: 2 / -2;
        grid-row: 2 / 3;
        align-self: flex-end;
        justify-self: flex-end;
    }

    &__description {
        grid-column: 2 / -2;
        grid-row: 3 / 4;
        align-self: center;
        justify-self: center;

        text-align: center;

        @include respond(phone) {
            margin-bottom: 2rem;
        }
    }

    &__container {
        grid-column: 2 / 3;
        grid-row: 4 / 5;
        
        display: grid;
        grid-template-columns: repeat(4, 22.5rem);
        grid-template-rows: auto;

        @include respond(tab-port) {
            grid-template-columns: repeat(4, 12.5rem);
        }

        @include respond(phone) {
            grid-template-columns: repeat(3, 13.3333rem);
        }

        @include respond(small-phone) {
            grid-template-columns: repeat(3, 10rem);
        }
    }

    // Product Components
    &__component {
        margin-bottom: 5rem;
        display: grid;
        grid-template-rows: 17rem 2rem;
        justify-content: center;
        transition: all .4s ease;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }

        &:active,
        &:focus {
            transform: scale(1);
            transition: all .3s ease;
        }

        @include respond(tab-port) {
            grid-template-rows: 11.5rem 2rem;
        }

        @include respond(small-phone) {
            grid-template-rows: 9.5rem 2rem;
            margin-bottom: 3.5rem;
        }
    }

    &__component-photo {
        width: 15rem;
        height: 15rem;
        align-self: flex-start;
        justify-self: center;

        border: 3px solid $color-teal;
        border-radius: 50%;
        box-shadow: $shadow-button;
        background-size: cover;
        background-position: center;

        transition: all .4s ease;

        @include respond(tab-port) {
            width: 10rem;
            height: 10rem;
        }

        @include respond(small-phone) {
            width: 8rem;
            height: 8rem;
        }
    }

    &__component:hover > &__component-photo {
        border: 3px solid $color-orange;
        box-shadow: $shadow-large;
    }

    &__component:active > &__component-photo {
        border: 3px solid $color-green;
        box-shadow: $shadow-button;
        transition: all .1s ease;
    }

    &__component-heading {
        text-align: center;
        max-width: 18rem;
        align-self: flex-start;

        transition: all .4s ease;

        @include respond(tab-port) {
            max-width: 11rem;
        }
        
        @include respond(small-phone) {
            max-width: 9rem;
        }
    }

    &__component:hover > &__component-heading {
        color: #2d2d2d;
    }
}
