// Active Large Sidebar
.large-sidebar-active {
    background-color: $color-teal !important;
}

.large-sidebar-section-active {
    color: $color-teal !important;
    background-color: $color-white !important;
}

.large-sidebar-section-active .large-sidebar__button-icon{
    fill: $color-teal !important;
}

.large-sidebar-active p {
    color: $color-white;
}

// Main Large Sidebar
.large-sidebar {
    grid-column: sidebar-start / sidebar-end;
    grid-row: 2 / -1;

    position: fixed;
    left: 0;
    display: flex;
    flex-direction: column;

    height: 100vh;
    width: 25rem;
    transform: translateX(-25rem);
    z-index: 9;

    background-color: $color-grey-light;

    @include respond(phone) {
        transform: translateX(-25rem);
        transition: all .3s ease;
        z-index: 20;
    }

    &__active {
        transform: translateX(0);

        @include respond(phone) {
            transform: translateX(0);
            box-shadow: $shadow-large;
        }
    }

    &__top {
        position: relative;
        height: 6rem;

        background-color: $color-white;

        @include respond(phone) {
            box-shadow: $shadow-medium;
        }
    }

    &__top-heading {
        position: relative;
        top: 2rem;
        left: 2.5rem;

        font-size: 1.8rem;
        color: $color-teal;
    }

    &__close-icon {
        position: relative;
        left: 20rem;
        top: -.8rem;

        width: 3rem;
        height: 3rem;
        fill: $color-teal;

        &:hover {
            cursor: pointer;
        }
    }

    &__container {
        overflow: scroll;
        // padding-bottom: 10rem;
    }

    &__header {
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        padding: 1rem 2rem;

        background-color: $color-grey;
        color: $color-white;
        font-size: 1.8rem;

        &:hover {
            background-color: $color-grey-hover;
            cursor: pointer;
        }

        &:active,
        &:focus {
            color: $color-teal;
        }
    }

    &__section {
        padding: 1.5rem 2rem;
        display: flex;
        align-items: center;

        border-bottom: $border-grey;
        color: $color-grey;
        font-size: 1.4rem;

        &:hover {
            background-color: $color-grey-light-hover;
            cursor: pointer;
        }
    }

    &__button-icon {
        width: 2rem;
        height: 2rem;
        margin-right: 2rem;
        margin-left: .2rem;
        
        fill: $color-grey;
    }
}