.line-s {
    width: 10rem;
    height: 3px;
    margin: 0 auto;

    &--orange {
        background-color: $color-orange;
    }

    &--green {
        background-color: $color-green;
    }
}

.line-m {
    height: 3px;
    width: 15rem;
    margin: 0 auto;

    &--orange {
        background-color: $color-orange;
    }

    &--green {
        background-color: $color-green;
    }
}
