@import "./swag";

.community {
    display: grid;
    grid-template-columns: 1fr repeat(2, 45rem) 1fr;
    grid-template-rows: 30rem 20rem 40rem 40rem 10rem;

    @include respond(tab-port) {
        grid-template-columns: 1fr repeat(2, 25rem) 1fr;
        grid-template-rows: 25rem 20rem repeat(4, 40rem) 10rem;
    }

    @include respond(phone) {
        grid-template-columns: 1fr repeat(2, 20rem) 1fr;
        grid-template-rows: 15rem 17rem repeat(4, 40rem) 10rem;
    }

    @include respond(small-phone) {
        grid-template-columns: 1fr repeat(2, 15rem) 1fr;
        grid-template-rows: 12rem 14rem repeat(4, 35rem) 10rem;
    }

    &__banner {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        background-size: cover;
        background-position: center;
    }

    &__banner-container {
        grid-column: 2 / -2;
        grid-row: 1 / 2;
        justify-self: center;
        align-self: center;
        text-align: center;

        background-color: rgba(255, 255, 255, 0.9);
        padding: 3rem;
        width: 60rem;

        border-radius: 15px;

        @include respond(tab-port) {
            width: 50rem;
            grid-column: 2 / -2;
        }

        @include respond(phone) {
            width: 100%;
            padding: 1.5rem;
        }
    }

    &__heading {
        color: $color-grey-hover;
        text-transform: uppercase;
        margin: 0;
    }

    &__border {
        margin: 2rem auto;
        height: 2px;
        width: 15rem;
        background-color: $color-green;

        @include respond(phone) {
            margin: 1rem auto;
        }
    }

    &__description {
        grid-column: 2 / -2;
        grid-row: 2 / 3;
        align-self: center;
        justify-self: center;

        text-align: center;
    }

    &__card {
        align-self: center;
        justify-self: center;
        width: 90%;
        height: 90%;

        box-shadow: $shadow-dark;
        text-decoration: none;
        border: 1px solid $color-teal;
        border-radius: 5px;
        overflow: hidden;

        transition: all .3s ease;

        display: flex;
        align-items: flex-end;

        &:hover {
            cursor: pointer;
            box-shadow: $shadow-large;
            transform: scale(1.02);
        }

        &--1 {
            grid-column: 2 / 3;
            grid-row: 3 / 4;
            background-image: url('../../../assets/community/community__card--1.jpg');
            background-size: cover;
            background-position: bottom;

            @include respond(tab-port) {
                grid-column: 2 / 4;
                grid-row: 3 / 4;
            }
        }

        &--2 {
            grid-column: 3 / 4;
            grid-row: 3 / 4;
            background-image: url('../../../assets/community/community__card--2.jpg');
            background-size: cover;
            background-position: bottom;

            @include respond(tab-port) {
                grid-column: 2 / 4;
                grid-row: 4 / 5;
            }
        }

        &--3 {
            grid-column: 2 / 3;
            grid-row: 4 / 5;
            background-image: url('../../../assets/community/community__card--3.jpg');
            background-size: cover;
            background-position: bottom;

            @include respond(tab-port) {
                grid-column: 2 / 4;
                grid-row: 5 / 6;
            }
        }

        &--4 {
            grid-column: 3 / 4;
            grid-row: 4 / 5;
            background-image: url('../../../assets/community/community__card--4.jpg');
            background-size: cover;
            background-position: bottom;

            @include respond(tab-port) {
                grid-column: 2 / 4;
                grid-row: 6 / 7;
            }
        }
    }

    &__card-banner {
        width: 100%;
        height: 10rem;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        @include respond(small-phone) {
            height: 8rem;
        }
    }

    &__card-line {
        height: 2px;
        width: 10rem;
        background-color: $color-green;
        margin-top: 1rem;
    }
}

// TEMPORARY CSS
.temp-community {
    display: flex;
    flex-direction: column;

    &__back-button {
        align-self: flex-end;
        margin-top: 6rem;
        margin-right: 6rem;

        @include respond(phone) {
            margin-top: 4rem;
            margin-right: 4rem;
        }

        @include respond(small-phone) {
            margin-top: 2rem;
            margin-right: 2rem;
        }
    }

    &__temp-heading {
        align-self: center;
        text-align: center;
        margin-top: 2rem;
        color: $color-grey;

        @include respond(small-phone) {
            font-size: 2.4rem;
        }
    }

    &__gif {
        width: 60%;
        height: 60%;
        display: flex;
        margin: 5rem auto;
    }
}
