.header {
    background-color: $color-white;
    grid-column: sidebar-start / main-end;

    position: sticky;
    top: 0;
    z-index: 10;

    display: flex;
    align-items: center;
    box-shadow: $shadow-medium;

    // MENU BUTTON

    &__menu-button {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        margin-left: 1rem;
        margin-right: auto;

        background-color: $color-white;
        border: none;
        outline: none;

        &:hover {
            background-color: $color-grey-light;
            cursor: pointer;
        }

        &:active {
            background-color: $color-teal;
        }

        &:active .header__menu-icon {
            fill: $color-white; 
        }
    }

    &__menu-icon {
        width: 3.5rem;
        height: 3.5rem;
        fill: $color-teal;
    }

    // MENU LOGO

    &__fab {
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;
        top: 2rem;

        width: 9rem;
        height: 9rem;

        background-color: $color-teal;
        border-radius: 50%;
        box-shadow: $shadow-medium;
        transition: all .2s ease;

        @include respond(phone) {
            width: 6rem;
            height: 6rem;
            top: 2rem;
        }

        &:hover {
            transform: translate(-50%, 0) scale(1.02);
            cursor: pointer;
        }

        &:active {
            transform: translate(-50%, 0) scale(.97);
            box-shadow: $shadow-dark;
        }
    }

    &__logo {
        position: relative;
        top: 4.5rem;
        left: 4.5rem;
        transform: translate(-50%, -50%);

        width: 6rem;
        height: 6rem;

        @include respond(phone) {
            top: 3rem;
            left: 3rem;

            width: 4rem;
            height: 4rem;
        }
    }

    // CART
    &__cart-button {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        margin-right: 2rem;

        border: none;
        outline: none;
        background-color: $color-white;

        @include respond(phone) {
            margin-right: 1rem;
        }

        &:hover {
            background-color: $color-grey-light;
            cursor: pointer;
        }

        &:active {
            background-color: $color-teal;
        }

        &:active .header__cart-icon {
            fill: $color-white; 
        }
    }

    &__cart-icon {
        width: 3rem;
        height: 3rem;

        fill: $color-teal;

        @include respond(phone) {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    // USER
    &__user-button {
        width: 5rem;
        height: 5rem;
        margin-right: 3rem;

        border: none;
        outline: none;
        border-radius: 50%;
        background-color: $color-white;

        @include respond(phone) {
            margin-right: 1rem;
        }

        &:hover {
            background-color: $color-grey-light;
            cursor: pointer;
        }

        &:active {
            background-color: $color-teal;
        }

        &:active .header__user-icon {
            fill: $color-white; 
        }
    }

    &__user-icon {
        width: 3rem;
        height: 3rem;

        fill: $color-teal;

        @include respond(phone) {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}