#about__1-heading-1 {
    text-align: center;
    margin-top: 6rem;
}

#about__1-paragraph-1 {
    text-align: center;
    margin-top: 4rem;

    @include respond(phone) {
        margin-top: 2rem;
    }
}

#about__1-button-container {
    width: 35rem;
    margin: 8rem auto;

    @include respond(phone) {
        width: 28rem;
        margin: 6rem auto;
    }
}

#about__1-video {
    margin: 0 auto;
    border: .8rem solid $color-orange;
    transition: all .5s;
    width: 887px;
    height: 506px;

    &:hover {
        border: .8rem solid $color-green;
    }

    @include respond(tab-land) {
        width: 710px;
        height: 405px;
    }

    @include respond(tab-port) {
        width: 511px;
        height: 292px;
    }

    @include respond(phone) {
        width: 358px;
        height: 204px;
        border: .5rem solid $color-orange;

        &:hover {
            border: .5rem solid $color-green;
        }
    }

    @include respond(small-phone) {
        width: 286px;
        height: 163px;
    }
}

#about__1-rendering {
    margin: 4rem auto 0;
    width: 50rem;
    height: 50rem;

    @include respond(phone) {
        width: 35rem;
        height: 35rem;
    }

    @include respond(phone) {
        width: 30rem;
        height: 30rem;
    }
}

#about__1-hours-heading {
    @include respond(tab-port) {
        margin-top: 4rem;
    }
}

#about__1-perks-container {
    margin: 8rem auto 10rem;

    @include respond(phone) {
        margin: 8rem auto 8rem;
    }
}

#about__1-perks-heading {
    margin: 8rem 0 4rem;

    @include respond(phone) {
        margin: 6rem 0 3rem;
    }
}

#about__1-list {
    list-style: none;
    align-self: center;
}

.list li {
    margin-top: 1rem;
}
