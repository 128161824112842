#what-we-do {
    grid-template-rows: 30rem 25rem 10rem repeat(2, 15rem) 5rem 10rem;

    @include respond(tab-port) {
        grid-template-rows: 30rem 25rem 10rem repeat(4, 15rem) 5rem 10rem;
    }

    @include respond(phone) {
        grid-template-rows: 20rem 23rem 10rem repeat(4, 15rem) 5rem 10rem;
    }

    @include respond(small-phone) {
        grid-template-rows: 20rem 25rem 10rem repeat(4, 15rem) 3rem 5rem;
    }

    // Banner
    &__banner {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        background-size: cover;
        background-position: center;
    }

    &__banner-container {
        grid-column: 2 / -2;
        grid-row: 1 / 2;
        justify-self: center;
        align-self: center;
        text-align: center;

        @include respond(tab-port) {
            grid-column: 1 / -1;
        }
    }

    &__banner-heading {
        text-transform: uppercase;
    }

    &__banner-border {
        margin: 2rem auto;
    }

    // Explaination
    &__explanation {
        grid-column: 2 / -2;
        grid-row: 2 / 3;
        justify-self: center;
        align-self: center;

        padding: 3rem;
        text-align: center;

        @include respond(tab-port) {
            width: 55rem;
            padding: 2rem;
        }

        @include respond(phone) {
            grid-column: 2 / -2;
            width: 40rem;
        }

        @include respond(small-phone) {
            padding: 1rem;
            width: 30rem;
        }
    }

    // Perks
    &__border {
        border: 3px solid $color-orange;
        grid-column: 2 / -2;
        grid-row: 3 / 7;
        width: 100%;

        @include respond(tab-port) {
            grid-column: 3 / 7;
            grid-row: 3 / 9;
        }

        @include respond(phone) {
            grid-column: 2 / 8;
            grid-row: 3 / 9;
        }
    }

    &__offer {
        grid-column: 2 / -2;
        grid-row: 3 / 4;
        justify-self: center;
        align-self: center;

        margin-top: 2rem;

        @include respond(tab-port) {
            grid-column: 2 / -2;
        }
    }

    &__text {
        margin-top: 2rem;
    }
}

.what-we-do {
    &__container {
        justify-self: center;
        align-self: center;

        text-align: center;
        padding: 3rem;

        &--1 {
            grid-column: 2 / 5;
            grid-row: 4 / 5;

            @include respond(tab-port) {
                grid-column: 3 / 5;
                grid-row: 4 / 5;
            }

            @include respond(phone) {
                grid-column: 2 / 5;
            }
        }

        &--2 {
            grid-column: 5 / 8;
            grid-row: 4 / 5;

            @include respond(tab-port) {
                grid-column: 5 / 7;
                grid-row: 4 / 5;
            }

            @include respond(phone) {
                grid-column: 5 / 8;
            }
        }

        &--3 {
            grid-column: 8 / 11;
            grid-row: 4 / 5;

            @include respond(tab-port) {
                grid-column: 3 / 5;
                grid-row: 5 / 6;
            }

            @include respond(phone) {
                grid-column: 2 / 5;
            }
        }

        &--4 {
            grid-column: 11 / 14;
            grid-row: 4 / 5;

            @include respond(tab-port) {
                grid-column: 5 / 7;
                grid-row: 5 / 6;
            }

            @include respond(phone) {
                grid-column: 5 / 8;
            }
        }

        &--5 {
            grid-column: 2 / 5;
            grid-row: 5 / 6;

            @include respond(tab-port) {
                grid-column: 3 / 5;
                grid-row: 6 / 7;
            }

            @include respond(phone) {
                grid-column: 2 / 5;
            }
        }

        &--6 {
            grid-column: 5 / 8;
            grid-row: 5 / 6;

            @include respond(tab-port) {
                grid-column: 5 / 7;
                grid-row: 6 / 7;
            }

            @include respond(phone) {
                grid-column: 5 / 8;
            }
        }

        &--7 {
            grid-column: 8 / 11;
            grid-row: 5 / 6;

            @include respond(tab-port) {
                grid-column: 3 / 5;
                grid-row: 7 / 8;
            }

            @include respond(phone) {
                grid-column: 2 / 5;
            }
        }

        &--8 {
            grid-column: 11 / 14;
            grid-row: 5 / 6;

            @include respond(tab-port) {
                grid-column: 5 / 7;
                grid-row: 7 / 8;
            }

            @include respond(phone) {
                grid-column: 5 / 8;
            }
        }
    }

    &__icon {
        &--1 {
            fill: $color-orange;
            width: 3rem;
            height: 3rem;
        }

        &--2 {
            fill: $color-green;
            width: 3rem;
            height: 3rem;
        }
    }
}
