.temp-closing-modal {
    position: fixed;
    top: 50%;
    left: 50%;

    height: 45rem;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index: 100;
    
    background: $color-orange;
    box-shadow: $shadow-large;

    opacity: 0;
    animation: closingModalAnimation 1s ease 0s forwards;

    @include respond(tab-port) {
        height: 40rem;
    }

    @include respond(phone) {
        height: 45rem;
    }

    @include respond(small-phone) {
        height: 45rem;
    }
}

@keyframes closingModalAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}