#snackbar--success {
    position: fixed;
    bottom: 5rem;
    left: 5rem;

    background: #03d303;
    height: 5rem;
    width: 25rem;
    border-radius: 10px;
    box-shadow: 2px 5px 15px 0px rgba(42, 85, 140, .3);
    padding: 0 1rem 0 2rem;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    animation: snackbarFade .5s ease;
    z-index: 1000;
}

#snackbar--fail {
    position: fixed;
    bottom: 5rem;
    left: 5rem;

    background: #e90505;
    height: 5rem;
    width: 28rem;
    border-radius: 10px;
    box-shadow: 2px 5px 15px 0px rgba(42, 85, 140, .3);
    padding: 0 1rem 0 2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    animation: snackbarFade .5s ease;
    z-index: 1000;
}

#snackbar__close-container {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    margin-left: 1.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: all .3s ease;

    &:hover,
    &:focus,
    &:active {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.2);
    }
}

#snackbar__close-icon {
    color: #fff;
    font-weight: bold;
}
