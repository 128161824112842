// Colors
.teal {
    color: $color-teal !important;
}

.teal-dark {
    color: $color-teal-dark !important;
}

.green {
    color: $color-green !important;
}

.orange {
    color: $color-orange !important;
}

.white {
    color: $color-white !important;
}

.grey {
    color: $color-grey-hover;
}

// Heading Typography
.h1 {
    font-size: 4.5rem;
    color: $color-grey;

    @include respond(phone) {
        font-size: 4rem;
    }

    @include respond(small-phone) {
        font-size: 3.2rem;
    }
}

.h2 {
    font-size: 3.4rem;
    color: $color-grey;

    @include respond(tab-port) {
        font-size: 2.4rem;
    }

    @include respond(phone) {
        font-size: 2rem;
    }

    @include respond(small-phone) {
        font-size: 1.8rem;
    }
}

.h3 {
    font-size: 2.4rem;
    color: $color-grey;

    @include respond(phone) {
        font-size: 1.8rem;
    }

    @include respond(small-phone) {
        font-size: 1.6rem;
    }
}

.h4 {
    font-size: 2rem;
    color: $color-grey;

    @include respond(tab-port) {
        font-size: 1.8rem;
    }

    @include respond(phone) {
        font-size: 1.6rem;
    }

    @include respond(small-phone) {
        font-size: 1.4rem;
    }
}

.h5 {
    font-size: 1.8rem;
    color: $color-grey;

    @include respond(tab-land) {
        font-size: 1.4rem;
    }

    @include respond(tab-port) {
        font-size: 1.2rem;
    }
}

.h6 {
    font-size: 1.4rem;
    color: $color-grey;
}

// Paragraph Typography
.p-xs {
    font-size: 1.2rem;
    color: $color-grey;
}

.p-s {
    font-size: 1.4rem;
    color: $color-grey;

    @include respond(small-phone) {
        font-size: 1rem;
    }
}

.p-m {
    font-size: 1.6rem;
    color: $color-grey;

    @include respond(tab-port) {
        font-size: 1.4rem;
    }

    @include respond(phone) {
        font-size: 1.2rem;
    }

    @include respond(small-phone) {
        font-size: 1.1rem;
    }
}

.p-l {
    font-size: 1.8rem;
    color: $color-grey;

    @include respond(tab-port) {
        font-size: 1.6rem;
    }

    @include respond(phone) {
        font-size: 1.4rem;
    }

    @include respond(small-phone) {
        font-size: 1.2rem;
    }
}

.p-xl {
    font-size: 2rem;
    color: $color-grey;

    @include respond(tab-port) {
        font-size: 1.8rem;
    }

    @include respond(phone) {
        font-size: 1.4rem;
    }

    @include respond(small-phone) {
        padding: 1.2rem;
    }
}

// Font Weights
.l {
    font-weight: 100;
}

.d {
    font-weight: 300;
}

.b  {
    font-weight: 900;
}

// Text Transfroms
.uppercase {
    text-transform: uppercase;
}

// Aligning
.center {
    text-align: center;
}
